<template>
  <div class="container-fluid my-5">
    <div class="row">
      <div class="col-md-5">
        <div class="sticky">
          <div class="card">
            <div class="card-body">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form id="search-form" @submit.prevent="handleSubmit(handleDates)">
                  <b-form-group>
                    <ValidationProvider vid="date_range" v-slot="{ errors }">
                      <HotelDatepicker
                        :alwaysVisible="true"
                        :showSingleMonth="true"
                        :disabledDates="blockedDates"
                        :enableCheckout="true"
                        :halfDay="false"
                        format="DD-MM-YYYY"
                        @check-in-changed="updateCheckin"
                        @check-out-changed="updateCheckout"
                        :periodDates="periodDates"
                        :showPrice="true"
                        priceSymbol="€"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>

                  <div class="mb-5">
                    <b-button type="submit" block variant="success">Select</b-button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <h5 class="text-danger mb-4" v-if="!selected">Please select dates to reserve this Accommodation</h5>
        <div class="card mb-4" v-if="lodging">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 mt-3">
                <div class="card-image">
                  <img
                    alt=""
                    :src="lodging.attributes.thumbnail"
                    class="img-fluid mx-auto d-block w-100 img-height"
                  />
                </div>
              </div>

              <div v-if="lodging" class="col-md-7 mt-3">
                <ul class="list-unstyled list-desc">
                  <strong>
                    <h5>
                      {{ lodging.attributes.name }}
                    </h5>
                  </strong>

                  <p class="text-dark capitalize">
                    {{ lodging.attributes.country.name }} - {{ lodging.attributes.region.name }}
                  </p>

                  <li>
                    <p class="mt-3 font-size-12">
                      {{ lodging.attributes.description }}
                    </p>
                  </li>

                  <span
                    v-for="highlight in lodging.attributes.highlights"
                    :key="highlight"
                    class="badge badge-light mr-2 mt-2 badge-pill font-size-12"
                  >
                    {{ highlight }}
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="selected">
          <b-form id="booking-form" class="mb-5" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card mb-4">
              <div class="card-body">
                <h4>Complete your reservation</h4>

                <hr />
                <h5 class="mb-4">Fill out your booking information</h5>

                <b-row>
                  <b-col sm="6">
                    <b-form-group label="Adults" label-for="adults">
                      <ValidationProvider rules="required|minimum:0" v-slot="{ errors }">
                        <b-form-input
                          v-model="reservation.adults"
                          type="number"
                          id="adults"
                          placeholder="No of adults"
                          @change="onGuestsChange"
                        ></b-form-input>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group label="Children" label-for="children">
                      <ValidationProvider rules="required|minimum:0" v-slot="{ errors }">
                        <b-form-input
                          v-model="reservation.children"
                          type="number"
                          id="children"
                          placeholder="No of children"
                          @change="onGuestsChange"
                        ></b-form-input>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group label="Add ons" label-for="additional_costs">
                  <Multiselect
                    id="additional_costs"
                    v-model="reserved_add_ons"
                    :options="additionalCostsName"
                    track-by="value"
                    label="text"
                    :multiple="true"
                    :allow-empty="true"
                    deselect-label="Unselect"
                    @input="calculateAdditionalCost"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label="Discount Code" label-for="discount">
                  <ValidationProvider vid="discount" v-slot="{ errors }">
                    <b-input-group>
                      <b-form-input
                        v-model="discountCode"
                        id="discount"
                        placeholder="Enter discount code"
                      ></b-form-input>

                      <template v-if="discountCode" #append>
                        <b-button @click="applyDiscount" variant="primary">Apply</b-button>
                      </template>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="discountValid" class="text-success">Discount applied</small>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="ml-0" label="Notes" label-for="notes">
                  <textarea
                    id="notes"
                    v-model="booking.notes"
                    class="form-control"
                    rows="5"
                    placeholder="Enter if you have any special requirements or allergies.."
                  ></textarea>
                </b-form-group>

                <h5 class="mt-5 mb-4">Fill out your personal information</h5>
                <b-form-group label="First name" label-for="first_name">
                  <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                    <b-form-input
                      id="first_name"
                      v-model="booking.customer.first_name"
                      placeholder="Enter first name"
                    ></b-form-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group label="Last Name" label-for="last_name">
                  <ValidationProvider rules="required|min:2" v-slot="{ errors }">
                    <b-form-input
                      id="last_name"
                      v-model="booking.customer.last_name"
                      placeholder="Enter last Name"
                    ></b-form-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group label="Email" label-for="email">
                  <ValidationProvider rules="email|required" v-slot="{ errors }">
                    <b-form-input
                      id="email"
                      v-model="booking.customer.email"
                      type="email"
                      placeholder="Enter email"
                    ></b-form-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group label="Phone number" label-for="phone_number">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      id="phone_number"
                      v-model="booking.customer.phone_number"
                      placeholder="Enter phone #"
                    ></b-form-input>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </div>

            <b-alert v-if="errors" show variant="danger">
              <div v-for="error in errors" :key="error">
                <li v-for="key in error" :key="key">
                  {{ key }}
                </li>
              </div>
            </b-alert>

            <div class="card mb-4">
              <div class="card-body">
                <h4 class="mb-4">Booking Details</h4>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    Checkin date: <strong>{{ start_date | dateInWords }}</strong>
                  </div>

                  <div class="col-md-6 mb-3">
                    Checkout date: <strong>{{ end_date | dateInWords }}</strong>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    Accommodation: <strong>{{ lodging.attributes.name }}</strong>
                  </div>

                  <div class="col-md-6 mb-3">
                    No of days: <strong>{{ getNights() }}</strong>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    Adults: <strong>{{ reservation.adults }}</strong>
                  </div>

                  <div class="col-md-6 mb-3">
                    Children: <strong>{{ reservation.children }}</strong>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-7 mb-3">
                    <div class="d-flex flex-wrap justify-content-between">
                      Avg. price per night
                      <strong>{{ parseFloat(price / getNights()).toFixed(2) }}</strong>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-7 mb-3">
                    <div class="d-flex flex-wrap justify-content-between">
                      Price
                      <strong>{{ parseFloat(price).toFixed(2) }}</strong>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-7 mb-3">
                    <div class="d-flex flex-wrap justify-content-between">
                      Add Ons Cost:
                      <strong>{{ parseFloat(additionalCost).toFixed(2) }}</strong>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-7 mb-3">
                    <div class="d-flex flex-wrap justify-content-between">
                      Discount:
                      <strong>-{{ discountValue }}</strong>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-md-7 mb-3">
                    <div class="d-flex flex-wrap justify-content-between">
                      Total Price:
                      <strong>{{ totalPrice }}</strong>
                    </div>
                  </div>
                </div>

                <b-button type="submit" block variant="primary" class="mt-5">Book Now</b-button>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import HotelDatepicker from "vue-hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
import appConfig from "@/app.config";
import {
  showLodging,
  showBooking,
  getPrices,
  getAdditionalCosts,
  getDiscounts,
  getRules,
  createBooking,
} from "@/store/api";
import moment from "moment";

export default {
  page: {
    title: "Booking",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    HotelDatepicker,
  },

  data() {
    return {
      bookings: [],
      errors: "",
      reserved_add_ons: [],
      availabilities: [],
      additionalCosts: [],
      rules: [],
      reservation: {
        checkin: "",
        checkout: "",
        adults: 1,
        children: 0,
        lodging_id: this.$route.params.id,
        discount_id: "",
        reserved_add_ons_attributes: [],
      },
      booking: {
        customer: {
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
        },
        notes: "",
        reservations_attributes: [],
      },
      start_date: "",
      end_date: "",
      lodging: "",
      price: "",
      requiredCost: "",
      additionalCost: "",
      discount: "",
      discountCode: "",
      selected: false,
      discountValid: false,
    };
  },

  created() {
    showLodging(this.$route.params.id, moment(Date.now()).format("YYYY-MM-DD")).then((response) => {
      this.lodging = response;
      if (this.$route.query.checkin && this.$route.query.checkout) {
      this.start_date = this.$route.query.checkin;
      this.end_date = this.$route.query.checkout;
      this.selected = true;
      this.handleDates();
    }
    });
    showBooking(this.$route.params.id, moment(Date.now()).format("YYYY-MM-DD")).then((response) => {
      this.bookings = response.availabilities;
    });
  },

  filters: {
    dateInWords(date) {
      return moment(date).format("MMM D, YYYY");
    },
  },

  computed: {
    additionalCostsName() {
      return this.additionalCosts
        .filter((additionalCost) => {
          return !additionalCost.attributes.required;
        })
        .map((additionalCost) => {
          return {
            value: additionalCost.id,
            text: additionalCost.attributes.title,
            price: additionalCost.attributes.price,
            fixed_price: additionalCost.attributes.fixed_price,
          };
        });
    },

    discountValue() {
      if (this.discount) {
        if (this.discount.attributes.percentage) {
          return parseFloat(this.price * this.discount.attributes.value * 0.01).toFixed(2);
        } else {
          return parseFloat(this.discount.attributes.value).toFixed(2);
        }
      } else {
        return 0;
      }
    },

    totalPrice() {
      return parseFloat(this.price + this.additionalCost - this.discountValue).toFixed(2);
    },

    periodDates() {
      return this.bookings.map((price) => {
        return {
          startAt: price.date,
          endAt: moment(price.date).add(parseInt(price.minimum_stay), "days").format("YYYY-MM-DD"),
          price: parseFloat(price.amount),
          periodType: "nightly",
          minimumDuration: parseInt(price.minimum_stay),
        };
      });
    },

    blockedDates() {
      return this.bookings
        .filter((price) => price.blockage == true)
        .map((price) => price.date);
    },
  },

  methods: {
    onGuestsChange() {
      if (this.rules.length) {
        this.calculatePrice();
        this.calculateExtraGuestCost();
        this.calculateDiscount();
      }
    },

    getNights() {
      return moment(this.end_date).diff(this.start_date, "days");
    },

    applyDiscount() {
      getDiscounts(this.lodging.id, this.discountCode).then((response) => {
        this.discount = response;
        if (!this.discount) {
          this.$refs.form.setErrors({ discount: "Discount code not valid" });
          this.discountValid = false;
        } else {
          this.discountValid = true;
        }
      });
    },

    handleDates() {
      if (!this.start_date || !this.end_date) {
        this.$refs.form.setErrors({ date_range: "Date range not selected" });
      } else {
        getPrices(
          this.lodging.id,
          moment(this.start_date).format("YYYY-MM-DD"),
          moment(this.end_date).subtract(1, "days").format("YYYY-MM-DD")
        ).then((response) => {
          this.prices = response;
          this.calculatePrice();
        });
        getAdditionalCosts(this.lodging.id).then((response) => {
          this.additionalCosts = response;
          this.calculateRequiredCost();
          this.calculateAdditionalCost();
        });
        getRules(
          this.lodging.id,
          moment(this.start_date).format("YYYY-MM-DD"),
          moment(this.end_date).subtract(1, "days").format("YYYY-MM-DD")
        ).then((response) => {
          this.rules = response;
          this.onGuestsChange();
        });
        this.selected = true;
      }
    },

    updateCheckin(date) {
      this.start_date = date;
    },

    updateCheckout(date) {
      this.end_date = date;
    },

    calculatePrice() {
      this.price = 0;
      for (let price of this.prices) {
        this.price += parseFloat(price.attributes.amount);
      }
      let remainingDays = moment(this.end_date).diff(this.start_date, "days") - this.prices.length;
      this.price += parseFloat(this.lodging.attributes.base_price) * remainingDays;
    },

    calculateRequiredCost() {
      this.requiredCost = 0;
      for (let additionalCost of this.additionalCosts) {
        if (additionalCost.attributes.required) {
          if (additionalCost.attributes.fixed_price) {
            this.requiredCost += parseFloat(additionalCost.attributes.price);
          } else {
            this.requiredCost +=
              parseFloat(additionalCost.attributes.price) * moment(this.end_date).diff(this.start_date, "days");
          }
        }
      }
    },

    calculateAdditionalCost() {
      this.additionalCost = this.requiredCost;
      for (let i = 0; i < this.reserved_add_ons.length; i++) {
        if (this.reserved_add_ons[i].fixed_price) {
          this.additionalCost += parseFloat(this.reserved_add_ons[i].price);
        } else {
          this.additionalCost +=
            parseFloat(this.reserved_add_ons[i].price) * moment(this.end_date).diff(this.start_date, "days");
        }
      }
    },

    calculateDiscount() {
      if (this.discount != null) {
        if (this.discount.percentage) {
          this.reservation.discount_value = this.reservation.price * this.discount.discount_value * 0.01;
        } else {
          this.reservation.discount_value = this.discount.discount_value;
        }
      } else {
        this.reservation.discount_value = 0;
      }
    },

    calculateExtraGuestCost() {
      let extraGuests = 0;
      let extraCost = 0;
      for (let rule of this.rules) {
        if (this.reservation.adults && this.reservation.children) {
          extraGuests =
            parseInt(this.reservation.adults) +
            parseInt(this.reservation.children) -
            rule.attributes.charge_after_guests;
        } else if (this.reservation.adults) {
          extraGuests = parseInt(this.reservation.adults) - rule.attributes.charge_after_guests;
        } else if (this.reservation.children) {
          extraGuests = parseInt(this.reservation.children) - rule.attributes.charge_after_guests;
        }

        if (this.price && extraGuests > 0) {
          let totalDays = moment(this.end_date).diff(this.start_date, "days");
          let weight;
          if (
            moment(rule.attributes.start_date).isSameOrBefore(this.start_date) &&
            moment(rule.attributes.end_date).isSameOrAfter(this.end_date)
          ) {
            weight = moment(this.end_date).diff(this.start_date, "days") / totalDays;
          } else if (
            moment(rule.attributes.start_date).isSameOrBefore(this.start_date) &&
            moment(rule.attributes.end_date).isSameOrBefore(this.end_date)
          ) {
            weight = (moment(rule.attributes.end_date).diff(this.start_date, "days") + 1) / totalDays;
          } else if (
            moment(rule.attributes.start_date).isSameOrAfter(this.start_date) &&
            moment(rule.attributes.end_date).isSameOrBefore(this.end_date)
          ) {
            weight = (moment(rule.attributes.end_date).diff(rule.attributes.start_date, "days") + 1) / totalDays;
          } else if (
            moment(rule.attributes.start_date).isSameOrAfter(this.start_date) &&
            moment(rule.attributes.end_date).isSameOrAfter(this.end_date)
          ) {
            weight = moment(this.end_date).diff(rule.attributes.start_date, "days") / totalDays;
          }
          if (rule.attributes.percentage) {
            extraCost += rule.attributes.extra_guest_cost * extraGuests * 0.01 * parseFloat(this.price) * weight;
          } else {
            extraCost += rule.attributes.extra_guest_cost * extraGuests * weight;
          }
        }
      }
      this.price = parseFloat(this.price) + extraCost;
    },

    onSubmit() {
      this.reservation.discount_id = this.discount.id;
      this.reservation.checkin = moment(this.start_date).format("YYYY-MM-DD");
      this.reservation.checkout = moment(this.end_date).format("YYYY-MM-DD");
      for (let i = 0; i < this.reserved_add_ons.length; i++) {
        this.reservation.reserved_add_ons_attributes.push({
          additional_cost_id: this.reserved_add_ons[i].value,
        });
      }
      this.booking.reservations_attributes.push(this.reservation);
      createBooking(this.booking)
        .then((response) => {
          console.log(response);
          this.$router.push("/bookings");
        })
        .catch((error) => {
          this.reservation.reserved_add_ons_attributes = [];
          this.booking.reservations_attributes = [];
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
}
.img-height {
  height: 175px;
}
</style>
